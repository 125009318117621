import React from "react";

import { H2 } from "../../components/typography";
import { useScrollToTop } from "../../hooks";
import FormPage from "../FormPage";

const mailTo = "mailto:scott.talley@cru.org?subject=Question%20about%20a%20free%20copy%20of%20A%20Grander%20Story";

const GranderStory = () => {
  useScrollToTop();
  return (
    <FormPage
      mailTo={mailTo}
      prefix="For questions, contact "
      title={
        <H2 color="black" $marginBottom={32}>
          {`Request a Free Copy of\n`}
          <i>A Grander Story</i>
        </H2>
      }
      fields={{
        firstName: {
          value: null,
          isValid: true,
        },
        lastName: {
          value: null,
          isValid: true,
        },
        email: {
          value: null,
          isValid: true,
        },
        department: {
          value: null,
          isValid: true,
        },
        freeCopy: {
          value: null,
          isValid: true,
        },
      }}
      type="GRANDER_STORY"
    />
  );
};

export default GranderStory;
