import React from "react";
import styled from "styled-components";

import Hero from "../../components/Hero";
import { H1, H3, P } from "../../components/typography";
import { useScrollToTop } from "../../hooks";

const hero = require("./hero.jpg");

const Section = styled.div`
  flex-direction: column;

  ${p => p.theme.size.padding()};
`;

const Title = styled(H1)`
  font-family: ${p => p.theme.font.poppinsRegular};
  margin-bottom: 32px;
`;

const COLOR = "black";

const copyProps = {
  color: COLOR,
  $marginBottom: 70,
};

const h3Props = {
  color: COLOR,
  $marginBottom: 32,
};

const faithStatements = [
  "Jesus is the Christ, the Son of God.",
  "Jesus died to save us from our sins.",
  "Jesus was resurrected from the dead on the third day.",
  "Jesus is now in heaven at the right hand of the Father interceding for His followers.",
  "Jesus will come again to judge the living and the dead at the final judgment.",
  "We believe that the Bible (Old and New Testaments) is the uniquely inspired Word of God.",
  "We believe that salvation is imparted to us through faith in Christ’s finished work on the cross and because of God’s grace.",
];

const About = () => {
  useScrollToTop();

  return (
    <>
      <Hero
        src={hero}
        size="h2"
        copy="We want to present the Truth about Jesus Christ with intellectual and emotional integrity, while providing fellowship for Christians in academia."
      />
      <Section>
        <Title color="black">About Faculty Commons at UK</Title>
        <i>
          <P {...copyProps} $large>
            University of Kentucky Faculty Commons is about bringing the hope of Jesus to the whole campus — professors
            and students — and the world. We are an interdenominational movement with many faculty and staff involved.
          </P>
        </i>
        <H3 {...h3Props}>Mission Statement</H3>
        <P {...copyProps}>
          Our mission is to encourage and assist one another to grow in our spiritual and professional lives in a godly
          manner, and to share our faith in Christ in appropriate ways on the campus and around the world. We want to
          present the Truth about Jesus Christ with intellectual and emotional integrity, while providing fellowship for
          Christians in academia.
        </P>
        <H3 {...h3Props}>Statement of Faith</H3>
        <P color={COLOR} $marginBottom={16}>
          We welcome all who believe in and follow Jesus Christ as Lord and Savior.The following are our primary
          doctrinal beliefs:
        </P>
        <ul>
          {faithStatements.map((statement, index) => (
            <li key={index}>
              <P color={COLOR} $marginBottom={8}>
                {statement}
              </P>
            </li>
          ))}
        </ul>
      </Section>
    </>
  );
};

export default About;
