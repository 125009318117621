import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { P } from "../../typography";

const StyledP = styled(P)`
  ${p => p.$bottom && `font-size: 0.9rem;`};
  color: ${p => (p.$error ? p.theme.color.error : p.theme.color.black)};
`;

const Label = ({ forId, hasError, isBottom, isRequired = false, text }) => (
  <StyledP
    as="label"
    $bottom={isBottom}
    color="black"
    $marginBottom={6}
    htmlFor={forId}
    $error={hasError}
  >
    {`${text} ${isRequired ? "*" : ""}`}
  </StyledP>
);

Label.propTypes = {
  forId: PropTypes.string,
  hasError: PropTypes.bool,
  isBottom: PropTypes.bool,
  isRequired: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default Label;
