import React from "react";

import { FieldWrapper, GenericInput } from "../components";
import Label from "../Label";
import { fieldType, handleChange } from "../utils";

const TextArea = ({
  field,
  hasError = false,
  isRequired = false,
  name,
  onChange,
  placeholder,
}) => (
  <FieldWrapper>
    <Label
      isRequired={isRequired}
      text={name}
      forId={field}
      hasError={hasError}
    />
    <GenericInput
      name={field}
      onChange={handleChange(onChange)}
      as="textarea"
      type="text"
      placeholder={placeholder}
      style={{ height: 75 }}
      required={isRequired}
      id={field}
      $error={hasError}
    />
  </FieldWrapper>
);

TextArea.propTypes = fieldType;

export default TextArea;
