import styled, { css } from "styled-components";

import { H1, H2 } from "../typography";

const text = css`
  width: 50%;

  @media ${p => p.theme.media.tablet} {
    width: 100%;
  }
`;

export const Small = styled(H2)`
  ${text};
`;

export const Large = styled(H1)`
  ${text};
`;

export const Container = styled.div`
  background-color: black;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url(${p => p.src});
  background-position: center;
  background-size: cover;
  flex-direction: column;
  flex: 1 0 auto; /* IE */

  ${p => p.theme.size.padding(10)}
`;
