import React from "react";

import { FieldWrapper, GenericInput } from "../components";
import Label from "../Label";
import { fieldType, handleChange } from "../utils";

const Input = ({
  className,
  field,
  hasError = false,
  isBottom,
  isRequired = false,
  name,
  onChange,
  placeholder,
  style,
  type = "text",
}) => {
  return (
    <FieldWrapper $isBottom={isBottom} style={style} className={className}>
      <Label
        isRequired={isRequired && !isBottom}
        $isBottom={isBottom}
        text={name}
        forId={field}
        hasError={hasError}
      />
      <GenericInput
        $error={hasError}
        name={field}
        id={field}
        as="input"
        type={type}
        placeholder={placeholder}
        onChange={handleChange(onChange)}
        required={isRequired}
      />
    </FieldWrapper>
  );
};

Input.propTypes = fieldType;

export default Input;
