import React from "react";
import styled from "styled-components";

import NavButton from "../NavButton";

const Container = styled.footer`
  display: flex;
  background-color: ${p => p.theme.color.darkGrey};
  flex-direction: column;

  ${p => p.theme.size.padding()};
`;

const NavLink = styled(NavButton).attrs({
  linkColor: "white",
})`
  align-self: flex-end;
  background-image: none !important; // no underline on footer links
`;

const Footer = () => (
  <Container>
    <NavLink text="Home" to="/" />
    <NavLink text="About" to="/about" />
    <NavLink text="Contact Us" to="/contact-us" />
    <NavLink text="Give" href="https://give.cru.org/1076830" />
  </Container>
);

export default Footer;
