import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Bold, customUnderline, P } from "../typography";

const Button = styled(NavLink)`
  cursor: pointer;
  margin: 14px 25px;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(37, 53, 81, 0.2); /* mobile */

  &:is(.active):not(:has(img)) {
    ${p => customUnderline(p.theme.color.blue)}
  }
`;

const NavButton = ({
  children,
  className,
  href,
  linkColor = "blue",
  onClick,
  text,
  to = "/",
  type,
}) => {
  const Text = type === "bold" ? Bold : P;

  return (
    <Button
      as={href && "a"}
      target={href && "_blank"}
      to={to}
      href={href}
      className={className}
      onClick={onClick}
      rel={href && "noopener"}
    >
      {text && (
        <Text color={linkColor} style={{ whiteSpace: "nowrap" }}>
          {text}
        </Text>
      )}

      {children}
    </Button>
  );
};

NavButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  linkColor: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
};

export default NavButton;
