import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import styled from "styled-components";

import Footer from "../components/Footer";
import Header from "../components/Header";

import About from "./About";
import Contact from "./Contact";
import Events from "./Events";
import FourOFour from "./FourOFour";
import GranderStory from "./GranderStory";
import Home from "./Home";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  min-width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;

  @media ${p => p.theme.media.desktop} {
    margin-top: ${p => p.theme.size.headerHeight}px;
  }
`;

const Page = () => (
  <Router basename="/">
    <Container>
      <Header />
      <Content>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events" element={<Events />} />
          <Route path="/a-grander-story" element={<GranderStory />} />
          <Route path="*" element={<FourOFour />} />
        </Routes>
      </Content>
      <Footer />
    </Container>
  </Router>
);

export default Page;
