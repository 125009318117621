import React, { useRef, useState } from "react";
import styled from "styled-components";

import CustomHero from "../../components/CustomHero";
import { H2, H3, P } from "../../components/typography";
import EventCard from "./EventCard";

import Button from "../../components/Button";
import events from "./events";

const StyledCustomHero = styled(CustomHero)`
  height: 50vh;
  justify-content: center;
  align-items: center;

  @media ${p => p.theme.media.tall} {
    height: 10vh;
  }

  @media (max-width: 940px) {
    height: 100vh;
  }
`;

const VideoContainer = styled.div`
  max-width: 80%;
  position: relative;
  margin: 0 auto;
  margin-top: -40vh;
  margin-bottom: 64px;

  @media (max-width: 940px) {
    max-width: 100%;
    padding: 0 16px;
    position: absolute;
    margin-top: unset;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const Video = styled.video`
  max-width: 100%;
  max-height: 100vh;
`;

const VideoOverlay = styled.div`
  display: grid;
  padding: 32px;
  z-index: 1;
  position: absolute;
  transition: opacity 300ms ease-out;

  @media (max-width: 940px) {
    position: relative;
    opacity: 1 !important;
    padding: 32px 0;
  }
`;

const sand = require("./hero.jpg");

const today = new Date();

// sort and filter events
const nextEvents = events
  .reduce((acc, cur) => {
    if (cur.date > today) {
      return [...acc, cur];
    }
    return acc;
  }, [])
  .sort((a, b) => (a.date > b.date ? 1 : -1));

const Events = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <>
      <StyledCustomHero src={sand}>
        {
          /* <H2 as="h1">
          {nextEvents.length
            ? "Upcoming events."
            : "Check back for future events."}
        </H2> */
        }
      </StyledCustomHero>

      <VideoContainer>
        <Video
          ref={videoRef}
          poster="https://assets.ukfacultycommons.org/a-common-call-poster.jpg"
          disablePictureInPicture
          disableRemotePlayback
          playsInline
          onPlay={() => setIsPlaying(true)}
          onEnded={() => {
            if (videoRef.current) {
              videoRef.current.controls = false;
              videoRef.current.load();
            }
            setIsPlaying(false);
          }}
        >
          <source src="https://assets.ukfacultycommons.org/a-common-call.mp4" type="video/mp4" />
        </Video>

        <VideoOverlay style={{ opacity: Number(!isPlaying) }}>
          <H3 color="black">Upcoming - Common Call Conference!</H3>
          <P $large color="black" $marginBottom={16}>Registration Available Soon.</P>
          <Button
            text="Watch Overview ▶"
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.play();
                videoRef.current.controls = true;
              }
            }}
          />
        </VideoOverlay>
      </VideoContainer>

      {nextEvents.map((event, index) => (
        <EventCard
          key={event.name}
          event={event}
          last={index === nextEvents.length - 1}
        />
      ))}
    </>
  );
};

export default Events;
