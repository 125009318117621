import PropTypes from "prop-types";
import React from "react";

import { P } from "../typography";
import { Container, SingleCard, Title, To } from "./components";

const Cards = ({ content }) => (
  <Container>
    {content.map(({ title, href, copy }, index) => (
      <SingleCard key={index}>
        <Title>
          <To href={href} target="_blank" rel="noopener">
            {title}
          </To>
        </Title>
        <P $large>{copy}</P>
      </SingleCard>
    ))}
  </Container>
);

Cards.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string.isRequired,
      href: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Cards;
