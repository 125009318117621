import React from "react";

import { H2 } from "../../components/typography";
import { useScrollToTop } from "../../hooks";
import FormPage from "../FormPage";

const mailTo = "mailto:scott.talley@cru.org?subject=Faculty%20Commons%20at%20UK%20-%20Contact%20Page";

const Contact = () => {
  useScrollToTop();
  return (
    <FormPage
      copy="Contact us to learn more about our mission and work, or to become
  involved yourself."
      mailTo={mailTo}
      title={
        <H2 color="black" $marginBottom={32}>
          Contact Us
        </H2>
      }
      fields={{
        firstName: {
          value: null,
          isValid: true,
        },
        lastName: {
          value: null,
          isValid: true,
        },
        email: {
          value: null,
          isValid: true,
        },
        message: {
          value: null,
          isValid: true,
        },
      }}
      type="CONTACT_US"
    />
  );
};

export default Contact;
