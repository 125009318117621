import PropTypes from "prop-types";

/**
 * generic handleChange function that returns the name and value of the field
 * being changed
 * @param {function} onChange function called to update input
 */
export function handleChange(onChange) {
  return ({ target: { name, value, type, checked } }) => {
    if (type === "radio") {
      return onChange(name, value === "true");
    }
    return onChange(name, value);
  };
}

/**
 * Verifies if the user's email/username seems valid. This is as simple as
 * possible because most RegEx tests don't account for unicode.
 * @param  {String}  email email to check
 * @return {Boolean}       if the email seems to be a valid email
 */
export function isAcceptableEmail(email) {
  return isAcceptableString(email) && /.+@\S+\.\S{2,}/.test(email);
}

/**
 * Verifies if the string has length and non-space characters
 * @param  {String}  value string to check
 * @return {Boolean}       if the string meets the requirements
 */
export function isAcceptableString(value) {
  return value && value.trim().length > 0;
}

/**
 * Verifies that the value is not null
 * @param  {Any}    value  value to check
 * @return {Boolean}       value meets requirements
 */
export function isNotNull(value) {
  return value != null;
}

export const fieldType = {
  field: PropTypes.string.isRequired,
  hasError: PropTypes.bool,
  isBottom: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
};
