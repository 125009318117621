import PropTypes from "prop-types";
import React from "react";

import { Container } from "../Hero/components";

const CustomHero = ({ src, children, className }) => (
  <Container className={className} src={src}>
    {children}
  </Container>
);

CustomHero.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  src: PropTypes.node.isRequired,
};

export default CustomHero;
