import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "styled-components";
import Page from "./pages";
import theme from "./theme";
import GlobalStyle from "./theme/global";

const container = document.getElementById("root");
const root = createRoot(container);
// createRoot(container!) if you use TypeScript
root.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Page />
  </ThemeProvider>,
);
