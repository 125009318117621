import React from "react";

import Cards from "../../components/Cards";
import CustomHero from "../../components/CustomHero";
import Hero from "../../components/Hero";
import LargeContent from "../../components/LargeContent";
import SideBySideCopy from "../../components/SideBySideCopy";
import { useScrollToTop } from "../../hooks";
import AGranderStory from "./AGranderStory/index";
import Quote from "./Quote";

const hero = require("./hero.jpg");
const student = require("./student.jpg");
const green = require("./green.jpg");

const Home = () => {
  useScrollToTop();
  return (
    <>
      <Hero
        src={hero}
        copy={`Connecting to God,\nOur Calling, & Our\nCampus`}
        to="/about"
      />
      <SideBySideCopy
        leftCopy="The University of Kentucky Faculty Commons is a part of the worldwide missions organization, Cru."
        rightCopy="Faculty Commons is part of the global faculty network of Cru. On campuses all over the world we gather as men and women who love the academy, who find the person and works of Jesus of Nazareth to be both satisfying and true, and who look to Him as the beautiful hope of the world."
      />
      <Cards
        content={[
          {
            title: "Sign Up To Receive Frontlines",
            href: "https://www.cru.org/faculty/frontlines/",
            copy:
              "Frontlines is a quarterly newsletter filled with news, trends, and opportunities at America’s universities. Over 1000 faculty and friends of Faculty Commons receive this quarterly mailing. You’ll find the articles and resources helpful.",
          },
          {
            title: "Learn More About ‘A Common Call’",
            href: "https://www.cru.org/faculty/connect/a-common-call/",
            copy:
              "A Common Call is a conference for Christian professors for the purpose of learning strategies that are practical for making an eternal impact on our campuses",
          },
          {
            title: "Sign Up For Missional Moments",
            href: "https://www.cru.org/faculty/",
            copy:
              "During the Fall and Spring semesters, we offer weekly ideas for thinking and living missionally as faculty & grad students on today's campus. Most of these Missional Moments come from fellow faculty who are living missional lives.",
          },
        ]}
      />
      <LargeContent
        title="Faculty Commons works toward the day when . . ."
        to="/about"
        list={[
          "every campus has a movement of Christian professors who desire to make Christ known to their colleagues and students.",
          "every student has the opportunity to know professors who follow Christ.",
          "every academic discipline has a movement of Christian scholars who seek to restore the credibility of the Christian worldview in the marketplace of ideas.",
        ]}
        src={student}
      />
      <AGranderStory />
      <CustomHero src={green}>
        <Quote />
      </CustomHero>
    </>
  );
};

export default Home;
