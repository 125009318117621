import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";

import { Text, Wrapper } from "./components";

const Button = ({
  className,
  text,
  inverse = false,
  large,
  to,
  href,
  style,
  onClick,
}) => (
  <div className={className} onClick={onClick}>
    <Wrapper
      as={to ? NavLink : "a"}
      href={href}
      inverse={inverse}
      style={style}
      target={href && "_blank"}
      rel={href && "noopener"}
      to={to}
    >
      <Text $large={large}>{text}</Text>
    </Wrapper>
  </div>
);

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  inverse: PropTypes.bool,
  large: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
};

export default Button;
